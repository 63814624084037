<template>
    <div v-if='model && !model.is_null' :key='"posts_t_"+lang'>
        <div class='text-right last_update'>Data ultima modifica: {{ lastUpdateDate }}</div>

        <form @submit='save' @change='formIsDirty'>
            <div class='grid p-fluid formgrid'>
                <div class=' col-12'>
                    <Toolbar class='mt-2'>
                        <template v-slot:start>
                            <div><span class="publish_state">Stato di pubblicazione </span>
                                <Dropdown
                                    v-model="v$.model.publish_state.$model"
                                    :options="publish_states"
                                    optionLabel="label"
                                    optionValue="code"
                                    placeholder="Stato di pubblicazione" /></div>
                            <div class='ml-5'><span class="publish_state">Data di pubblicazione </span>
                                <Calendar
                                    :showTime="true"
                                    inputId="pub_at"
                                    v-model="v$.model.pub_at.$model"
                                    dateFormat="dd/mm/yy"
                                    :class="{'p-invalid': v$.model.pub_at.$invalid && v$.model.pub_at.$dirty}"
                                /></div>
                        </template>
                    </Toolbar>
                </div>
            </div>
            <div class='grid p-fluid formgrid mt-5'>
                <div class='field col-12 md:col-8'>
                    <div class="p-inputgroup">
                    <span class='p-float-label'>
                        <InputText id='t_slug'
                                   type='text'
                                   v-model="v$.model.slug.$model"
                                   :class="{'p-invalid': v$.model.slug.$invalid && v$.model.slug.$dirty}"
                        />
                        <Button label='Fix' class="p-button-warning" title='Correggi SLUG' @click='model.slug = sluggify(model.slug)'/>
                        <Button icon="pi pi-angle-double-down" class="p-button-primary" title='Eredita' @click='model.slug = row.slug'/>
                        <Button icon="pi pi-sort-up" class="p-button-info" title='Dal titolo' @click='model.slug = sluggify(model.title)'/>
                        <label for='t_slug'>Slug</label>
                    </span>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='title'
                                   type='text'
                                   v-model="v$.model.title.$model"
                                   :class="{'p-invalid': v$.model.title.$errors.length}"
                        />
                        <label for='title'>Titolo</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='subtitle'
                                   type='text'
                                   v-model="model.subtitle"
                        />
                        <label for='subtitle'>Sottotitolo</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 html-editor-wrap p-float-label'>
                    <HtmlEditor
                        v-model:content='model.body'
                        :key='"maga_pt_body_"+id+"_"+lang'
                        contentType='html'/>
                    <label>Testo</label>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-1'>
                    <span class='p-float-label'>
                        <InputNumber id='read_time'
                                   v-model="v$.model.read_time.$model"
                                   :class="{'p-invalid': v$.model.read_time.$errors.length}"
                        />
                        <label for='read_time'>Tempo di lettura</label>
                    </span>
                </div>
                <div class='field col-3'>
                    <InputSwitch v-model="model.flag_index" :id='"flag_index" + lang' class='inline-switch'/>
                    <label :for='"flag_index" + lang'>Index?</label>
                </div>
                <div class='field col-3'>
                    <InputSwitch v-model="model.flag_home_featured" :id='"flag_home_featured" + lang' class='inline-switch'/>
                    <label :for='"flag_home_featured" + lang'>Featured in Home?</label>
                </div>
                <div class='field col-3'>
                    <InputSwitch v-model="model.flag_category_featured" :id='"flag_category_featured" + lang' class='inline-switch'/>
                    <label :for='"flag_category_featured" + lang'>Featured in Categoria?</label>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-6'>
                    <uppy-dashboard
                        :uppy='uppyImg'
                        :props='uppyDashboardProps'
                    />
                </div>
                <div class='field col-12  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='mainImg'
                                   type='text'
                                   v-model="model.img"
                        />
                        <label for='mainImg'>Immagine principale</label>
                    </span>
                    <div v-if='model.img' class='text-center'>
                        <img :src='model.img' alt='Model Image' :height='uppyDashboardProps.height-30'/>
                    </div>
                </div>
            </div>

            <div class="grid">
                <div class="col-12">
                    <DataTable
                        :value="imgRepo"
                        :scrollable="true"
                        scrollHeight="400px"
                        scrollDirection="both"
                        class="mt-3 p-shadow-1">
                        <template #empty>
                            Non ci sono immagini caricate.
                        </template>
                        <Column header="Url" :style="{width:'70%'}">
                            <template #body="{data}">
                                <span @click='copyText(data.url)' class='pointer' title='Copia'><i class="pi pi-copy"></i> {{data.url}}</span>
                            </template>
                        </Column>
                        <Column header="Img" :style="{width:'25%'}">
                            <template #body="{data}">
                                <img :src='data.url' alt='Image available' height='75'/>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12'>
                    <uppy-dashboard
                        :uppy='uppyRepo'
                        :props='uppyDashboardProps'
                    />
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='seo_title'
                                   type='text'
                                   v-model="model.seo_title"
                        />
                        <label for='seo_title'>SEO Title</label>
                    </span>
                </div>
                <div class='field col-12  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='seo_description'
                                   type='text'
                                   v-model="model.seo_description"
                        />
                        <label for='seo_title'>SEO Description</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12'>
                    <RelatedBooks :books='model.books' @book-added='addBook' @book-removed='removeBook'/>
                </div>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' :load='loading'/>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';
import Uploader from '../../../mixins/uploader';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Magazine/Posts');
const commonStore = createNamespacedHelpers('Common');

import useVuelidate from '@vuelidate/core'

import tools from '../../../libs/tools';
import model from './model';

import RelatedBooks from '../../../components/4books/RelatedBooks';

export default {
    data(){
        return {};
    },
    components: {
        RelatedBooks
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return model.translationValidation;
    },
    beforeUnmount() {
        this.resetUploads(model.uploads);
    },
    computed: {
        ...mapGetters(['row', 'loading']),
        ...commonStore.mapGetters(['languages', 'publish_states']),
        lastUpdateDate() {
            return tools.time.lastUpdate(this.model['last_update']);
        },
        imgRepo(){
            const ret = [];
            if (this.model.repo && this.model.repo.length){
                this.model.repo.split("|").forEach(src => {
                    ret.push({ url: src });
                });
            }
            return ret;
        }
    },
    watch: {
        row(n){
            this.initModel(n);
        },
        lang(){
            this.initModel(this.row);
        },
        dirty(n){
            if (n) this.formIsDirty();
            else this.formIsClean();
        },
        'model.lastUpload'(n){
            if (!n) return;
            if (this.model.repo) this.model.repo += "|";
            this.model.repo += n;
        }
    },
    mounted() {
        this.initModel(this.row);
        this.prepareUploads(model.uploads, this.lang);
    },
    methods: {
        ...mapMutations(['formIsDirty', 'formIsClean']),
        ...mapActions(['saveTranslation']),
        async save () {
            const result = await this.v$.$validate()
            if (!result) {
                this.ShowError("Errore", "Alcuni campi del form non sono corretti");
                return
            }

            //Books verification
            if (this.model.books && this.model.books.length){
                let error = "";
                this.model.books.forEach(b => {
                    if (!this.model.body.includes(tools.models.getBookEmbedCode(b))){
                        error = b.slug;
                    }
                });
                if (error){
                    this.ShowError("Attenzione", "Il codice del libro " + error + " NON viene usato nel BODY. Rimuovilo dai correlati prima di salvare.")
                    return false;
                }
            }

            this.saveTranslation({
                data: model.formatTranslation(this.model),
                parent_id: model.translationParentId(this.model)
            }).then(() => {
                this.formIsClean();
                this.ShowSuccess('Ottimo', 'Traduzione salvata con successo');
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        initModel(mod){
            this.dirty = false;
            const base = model.newTranslation(this.id, this.lang);
            if (mod && mod.translations && mod.translations[this.lang]){
                this.model = tools.models.fillTranslation(model.parseTranslation(mod.translations[this.lang]), base);
                if (!this.model.books) this.model.books = base.books;
                return;
            }

            this.model = base;
        },
        addBook(book){
                this.model.books.push(book)
        },
        removeBook(book, removeCode){
            //First check if it is used and then remove
            if (this.model.body.includes(removeCode)){
                this.ShowError("Attenzione", "Non si può rimuovere un libro il cui codice è usato nel BODY");
                return false;
            }
            this.model.books = this.model.books.filter(b => b.id !== book.id);
        }
    },
    props: {
        lang: {
            type: String,
            default: 'it'
        },
        id: {
            type: Number
        }
    },
    mixins: [
        Navigations, Notifications, Uploader
    ]
}
</script>
