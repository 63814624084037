import tools from '../../../libs/tools';
import { required, minLength, maxLength, minValue } from '@vuelidate/validators'

const model = {
    id: 0,
    slug: "",
    categories: [],
    translations: {}
};

const translation = {
    id: 0,
    last_update: 0,
    slug: "",
    mp_id: 0,
    locale: "",
    title: "",
    subtitle: "",
    seo_title: "",
    seo_description: "",
    publish_state: "",
    pub_at: null,
    img: "",
    body: "",
    read_time: 0,
    flag_index: true,
    flag_home_featured: false,
    flag_category_featured: false,
    repo: "",
    lastUpload: "",
    books: []
};

export default {
    ...tools.models.apiCommons(model, translation, "mp_id"),
    modelValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            categories: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(1)
            }
        }
    },
    translationValidation: {
        model: {
            read_time: {
                required,
                minValue: minValue(1)
            },
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            title: { required },
            publish_state: { required },
            pub_at: {
                validDate: tools.models.validators.PUBLISH_DATE
            }
        }
    },
    uploads: [
        { el: "uppyImg", target: "img", folder: "/images/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.IMAGE]},
        { el: "uppyRepo", target: "lastUpload", folder: "/images/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.IMAGE]},
    ]
};
